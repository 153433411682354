
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const submitButton = ref<HTMLButtonElement | null>(null);
    const showPassword = ref<boolean>(true);
    const loginpagedisable = ref<boolean>(false);

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
      password: Yup.string().min(4).required().label("Password"),
    });
    const hideShowPassword = () => {
      showPassword.value = !showPassword.value;
    };
    const dismiss = () => {
      setTimeout(() => {
        let element = document.getElementsByClassName("swal2-confirm")[0];
        if (element instanceof HTMLElement) {
          element.click();
        }
      }, 1000);
    };

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      loginpagedisable.value = true;
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = false;

        // Activate indicator
        submitButton.value?.setAttribute("data-kt-indicator", "on");
      }

      // Send login request
      store
        .dispatch(Actions.LOGIN, values)
        .then(() => {
          Swal.fire(
            {
              text: "You have successfully logged in!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            },
            dismiss()
          ).then(function () {
            // Go to page after successfully login
            router.push({ name: "dashboard" });
            location.replace("dashboard");
          });
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getErrors);
          console.log(error);
          console.log(store.getters.getErrors[error]);
          Swal.fire(
            {
              text: store.getters.getErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            },
            dismiss()
          );
        });

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      showPassword,
      hideShowPassword,
      loginpagedisable,
    };
  },
});
